import { FC } from 'react'

import type {
  CustomerIntegrationsQuery,
  CustomerIntegrationsQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import CustomerIntegrationsList from '../CustomerIntegrationsList'

export const QUERY = gql`
  query CustomerIntegrationsQuery {
    integrations: integrationsByClient {
      id
      active
      integrationName
      integrationType
      apiKey
      apiAccountKey
      apiSecret
      createdBy
      createdAt
      createdByUser {
        name
        avatarUrl
      }
    }
  }
`

type CellProps = CellSuccessProps<
  CustomerIntegrationsQuery,
  CustomerIntegrationsQueryVariables
>

export type ClientIntegration = CellProps['integrations'][0]

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const Loading = () => <LoadingSpinner />

export const Empty: FC<CellProps> = () => {
  return <CustomerIntegrationsList integrations={[]} />
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<CellProps> = ({ integrations }) => {
  return <CustomerIntegrationsList integrations={integrations} />
}
